import { throttle } from 'lodash';
import {
  SETTINGS_EVENT_ID,
  UPDATE_FULL_POST_STYLE,
} from '@wix/communities-blog-client-common';
import type { EditorAppContext } from '../../../types/editor-app-context.type';
import { COMPONENT_DATA_CHANGED, SITE_WAS_SAVED } from '../constants/events';
import concurrentEditing from './concurrent-editing';
import { setFullpostStyleParams } from './fullpost-layout-design-service';
import { savePostPageStyle } from './post-page-style';

export const addEventListeners = async (context: EditorAppContext) => {
  const { sdk } = context;
  await sdk.addEventListener(SITE_WAS_SAVED, async () => {
    try {
      await savePostPageStyle(context);
    } catch (e) {}
  });

  const throttledSetParams = throttle(setFullpostStyleParams, 1000);
  await sdk.addEventListener(COMPONENT_DATA_CHANGED, async (event) => {
    const eventType = (event?.detail?.previousData as any)?.content?.[
      SETTINGS_EVENT_ID
    ]?.payload;

    if (eventType === UPDATE_FULL_POST_STYLE) {
      await concurrentEditing.withApproval(async () => {
        throttledSetParams(context);
      });
    }
  });
};
